.navbar-container{
    height: 110px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 2px lightgray;
    box-shadow:0px 2px 8px #888888;
    padding-left: 10px;
    padding-right: 10px;
}
.navbar-container .logo{
    height: 100%;
    padding-left: 30px;
}
.navbar-container .search-box{
    position: absolute;;
    top:17%;
    left: 25%;
    display: flex;
    align-items: center;
    width: 50%;
    border-radius: 25px;
    background-color: #eeeeee;
    border: solid 1px gray;
}
.navbar-container .search-box .Input:focus{
    outline:none;
    border:none;
}
.navbar-container .AddListingsFloatButton{
    display: none;
}
.navbar-container .user-login-icon:hover{
    cursor: pointer;
}
.navbar-container input{
    width: 100%;
    height: 40px;
    background-color: #eeeeee;
    border-radius: 25px;
    padding-left: 10px;
    font-size: 16px;
    border: none;
}
.navbar-container input:focus{
    outline: none;
    border: none;
}
.navbar-container ul{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    width: 100%;
}
.navbar-container ul li{
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 18px;
    color: black;
    font-size: 16px;
    position: relative;
}
.navbar-container ul .l2::after{
    content: "";
    width:0%;
    height: 3px;
    background-color: #8b0000;
    position: absolute;
    bottom:0;
    right: 0;
    transition: all 1s ease;
}
.navbar-container ul .l2:hover::after{
    width: 100%;
}
.navbar-container ul .l2::before{
    content: "";
    width:0%;
    height: 3px;
    background-color: #8b0000;
    position: absolute;
    top:0;
    left: 0;
    transition: all 1s ease;
}
.navbar-container ul .l2:hover::before{
    width: 100%;
}
.navbar-container ul li Button{
    border: solid 1px #B17E4E;
}
.navbar-container ul Button:hover{
    background-color: darkred;
    border: solid 1px darkred;
    color: white;
}


@media(max-width:1024px)
{
    .navbar-container .logo {
        height:90%;
        padding-left: 10px;
    }
    .navbar-container ul{
        padding-right: 10px;
    }
    .navbar-container ul li{
        margin-right: 8px;
    }
}

@media(min-width:770px)
{
    .navbar-container .logo{
        width: 30%;
    }
    .navbar-container .side-menu{
        display: none;
    }
}

@media (max-width:768px){

    .navbar-container .logo {
        padding-left: 20px;
    }
    .navbar-container .side-menu{
        margin-right: 20px;
    }
    .navbar-container .user-login-icon .avatar{
        display: none;
    }
    .navbar-container .user-login-icon h5{
        display: none;
    }
    .navbar-container .nav .l1{
        display: none;
    }
}

@media (max-width:576px){
    
    .navbar-container .logo{
        height: 85%;
        padding-left: 10px;
    }
    .navbar-container .side-menu{
        margin-right: 10px;
    }

    .navbar-container .AddListings{
        display: none;
    }

    .navbar-container .AddListingsFloatButton{
        display: block;
        background-color: #B17E4E;
        color: white;
        font-size: 20px;
    }

}
@media (max-width:460px){

    .navbar-container .logo{
        height: 70%;
    }

}
@media (max-width:360px){

    .navbar-container .logo{
        height: 75%;
        padding-left:0px;
    }
    .navbar-container .side-menu{
        margin-right: 0px;
    }

}